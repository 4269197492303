<template>
  <div>
    <!-- 新增網域商品 -->
    <price-list-add-modal
      ref="domainAddModal"
      :is-free="searchIsFree"
      @refetch-data="refetchData"
    />

    <!-- 網域商品編輯 -->
    <price-list-edit-modal
      v-if="selected"
      ref="domainEditModal"
      :is-free="searchIsFree"
      :domain-item="selected"
      @refetch-data="refetchData"
    />

    <!-- 搜尋欄 -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>篩選器</h5>
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            lg="2"
            md="3"
            sm="6"
            class="mb-md-0 mb-1"
          >
            <label>上架狀態</label>
            <v-select
              v-model="searchDisplay"
              :options="displayOptions"
              class="w-100"
              :reduce="option => option.value"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>

              <template v-slot:option="option">
                <div class="d-flex align-items-center">
                  <span
                    class="mr-50 border state-dot"
                    :class="{ 'dot-gray': !option.value, 'dot-green-selected': option.value }"
                  />
                  {{ option.label }}
                </div>
              </template>

              <template #selected-option="option">
                <div class="d-flex align-items-center">
                  <span
                    class="mr-50 border state-dot"
                    :class="{ 'dot-gray': !option.value, 'dot-green-selected': option.value }"
                  />
                  {{ option.label }}
                </div>
              </template>
            </v-select>
          </b-col>

          <b-col
            cols="12"
            lg="2"
            md="3"
            sm="6"
            class="mb-md-0 mb-1"
          >
            <label>熱銷狀態</label>
            <v-select
              v-model="searchIsFire"
              :options="isFireOptions"
              :reduce="option => option.value"
              class="edit-col-select"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
          </b-col>

          <b-col
            cols="12"
            lg="5"
            md="6"
            sm="12"
            class="mb-md-0 mb-1"
          >
            <label>經銷商</label>
            <v-select
              v-model="searchDealerId"
              :options="dealerOptions"
              class="w-100"
              label="name"
              :reduce="option => option.id"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>

              <template v-slot:option="option">
                <div class="w-100">
                  <h6 class="font-weight-bold d-block text-nowrap show-text mb-0">
                    {{ option.name }}
                  </h6>
                  <small class="text-muted">
                    <div class="email-text">{{ resolveTDomainAgentId(option.domain_name_agent_id) }}</div>
                  </small>
                </div>
              </template>

              <template #selected-option="option">
                <h6 class="font-weight-bold d-block text-nowrap show-text mb-0">
                  {{ option.name }}
                </h6>
                <small class="text-muted">
                  &nbsp;( <small class="email-text">{{ resolveTDomainAgentId(option.domain_name_agent_id) }}</small> )
                </small>
                <!-- <span class="font-weight-bold">{{ option.name }}&nbsp;</span> <small>( {{ resolveTDomainAgentId(option.domain_name_agent_id) }} )</small> -->
              </template>
            </v-select>
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>

    <b-tabs
      v-model="searchIsFree"
      pills
    >
      <b-tab
        title="付費網域"
        active
      />

      <b-tab title="免費網域" />
    </b-tabs>

    <!-- 網域售價列表 -->
    <b-card no-body>
      <div class="mx-2 mt-2 mb-50">
        <b-row>
          <b-col
            cols="12"
            md="8"
            sm="6"
          >
            <h4 class="mb-0">
              網域商品列表
            </h4>
          </b-col>

          <b-col
            cols="12"
            md="4"
            sm="6"
          >
            <div class="text-nowrap d-flex justify-content-end">
              <div
                v-b-tooltip.hover.focus.v-secondary
                title="新增"
                class="d-flex align-items-center actions-link-btn"
                @click="onSubmitUpdate(null)"
              >
                <b-img
                  src="/dashboard/admin/images/table/plus.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="重新整理"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="refetchTable"
              >
                <b-img
                  src="/dashboard/admin/images/table/refresh.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="列表顯示"
                class="d-flex align-items-center actions-link-btn ml-25"
              >
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <b-img
                      src="/dashboard/admin/images/table/setting.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </template>

                  <b-dropdown-form>
                    <b-form-group>
                      <label class="mb-50">
                        列表顯示
                      </label>

                      <b-form-checkbox
                        v-for="column in tableColumnsSearchable"
                        :key="column.key"
                        v-model="column.select"
                        name="table-column"
                        class="mb-1"
                      >
                        {{ column.label }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-dropdown-form>

                </b-dropdown>
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="創建日期"
                class="d-flex align-items-center actions-link-btn ml-25"
                :class="timeRange ? 'actions-link-btn-active' : null"
                @click="() => { $refs.TableTimeRange.getData(timeRange) }"
              >
                <b-img
                  src="/dashboard/admin/images/table/calendar.svg"
                  class="actions-link-btn-image"
                  rounded
                />

                <TableTimeRange
                  ref="TableTimeRange"
                  table-explorer-id="TableTimeRange"
                  @call-back-data="time => timeRange = time"
                />
              </div>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 顯示個數/搜尋框 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示個數 -->
          <b-col
            cols="12"
            md="6"
            sm="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
          >
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="table-perPage-select"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
            <!-- per-page-selector d-inline-block   -->
          </b-col>

          <!-- 搜尋框 -->
          <b-col
            cols="12"
            md="6"
            sm="8"
          >
            <!-- 搜尋框 -->
            <div class="w-100 mr-1">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  debounce="500"
                  class="d-inline-block"
                  placeholder="搜尋..."
                />
                <b-input-group-append
                  v-if="searchQuery"
                  is-text
                >
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer text-muted"
                    @click="searchQuery = null"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 列表 -->
      <b-table
        ref="refDataListTable"
        :items="getDomainListData"
        :fields="tableColumnsFilter"
        responsive
        striped
        hover
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        class="position-relative"
        details-td-class="p-0"
        :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        <!-- 忙碌中 -->
        <template #table-busy>
          <b-skeleton-table
            :rows="5"
            :columns="tableColumnsFilter.length"
            :table-props="{ bordered: true, striped: true }"
          />
        </template>

        <!-- 查無資料 -->
        <template #empty>
          <div class="text-center my-5 animate__animated animate__fadeIn">
            <b-img
              :src="$store.state.app.themeImages.notFoundImg"
              fluid
              width="480"
              alt="查無資料"
            />
          </div>
        </template>

        <!-- 欄位: 編號 -->
        <template #cell(id)="data">
          <div class="table-col">
            <span>{{ data.item.id }}</span>
          </div>
        </template>

        <!-- 欄位: 上架 -->
        <template #cell(display)="data">
          <div class="table-col">
            <div
              v-if="data.item.display"
              v-b-tooltip.hover.focus.v-secondary
              title="上架中"
              class="state-dot dot-green"
              @click="onSubmitUpdate(data.item)"
            />
            <div
              v-else
              v-b-tooltip.hover.focus.v-secondary
              title="未啟用"
              class="state-dot dot-gray"
              @click="onSubmitUpdate(data.item)"
            />
          </div>
        </template>

        <!-- 欄位: 後綴 -->
        <template #cell(suffix)="data">
          <div class="d-flex align-items-center table-title justify-content-start">
            <div
              v-if="!searchIsFree"
              class="w-100"
            >
              <b-link
                class="font-weight-bold d-block text-nowrap show-text"
                style="font-size:1.2rem"
                :to="{ name: 'admin-domain-price-setting', params: { id: data.item.id } }"
              >
                {{ data.item.suffix }}
              </b-link>
            </div>

            <div
              v-else
              class="w-100"
            >
              <b-link
                class="font-weight-bold d-block text-nowrap show-text"
                style="font-size:1.2rem"
                @click="onSubmitUpdate(data.item)"
              >
                {{ data.item.suffix }}
              </b-link>
            </div>
          </div>
        </template>

        <!-- 欄位: 身分驗證 -->
        <template #cell(require_kyc)="data">
          <div class="table-col">
            <div class="kyc-state">
              <b-img
                rounded
                fluid
                class="kyc-state-image"
                :src="require('@/assets/images/pages/customer/id-card.svg')"
              />

              <b-img
                v-b-tooltip.hover.focus.v-secondary
                :title="ui.kycRequired[data.item.require_kyc]"
                rounded
                fluid
                class="kyc-state-type"
                :src="`/dashboard/admin/images/common/${data.item.require_kyc ? 'check' : 'cross'}.svg`"
              />
            </div>
          </div>
        </template>

        <!-- 欄位: 熱銷 -->
        <template #cell(isFire)="data">
          <div
            :class="data.item.is_fire ? null : 'nofire'"
            class="fire-img table-col"
            @click="onSubmitUpdate(data.item)"
          >
            <b-img
              :src="$store.state.app.themeImages.fireImg"
              height="25"
              width="25"
            />
          </div>
        </template>

        <!-- 欄位: 排序 -->
        <template #cell(rank)="data">
          <div class="table-col text-nowrap">
            <span>{{ data.item.rank }}</span>
          </div>
        </template>

        <!-- 欄位: 註冊價格 -->
        <template #cell(price_new)="data">
          <div class="table-col text-nowrap">
            <span v-if="!searchIsFree">{{ checkArrayAndPrice(data.item.price_new) }}</span>
            <span v-else>---</span>
          </div>
        </template>

        <!-- 欄位: 轉移價格 -->
        <template #cell(price_trans)="data">
          <div class="table-col text-nowrap">
            <span v-if="!searchIsFree">{{ checkArrayAndPrice(data.item.price_trans) }}</span>
            <span v-else>---</span>
          </div>
        </template>

        <!-- 欄位: 轉移費用 -->
        <template #cell(transfer)="data">
          <div class="table-col text-nowrap">
            <span v-if="!searchIsFree">{{ data.item.transfer ? `$ ${parseInt(data.item.transfer).toLocaleString()}` : '---' }}</span>
            <span v-else>---</span>
          </div>
        </template>

        <!-- 欄位: 經銷商 / 網域商 -->
        <template #cell(dealer)="data">
          <div class="table-col">
            <div class="w-100">
              <b-link
                class="font-weight-bold d-block text-nowrap show-text"
                @click="onSubmitUpdate(data.item)"
              >
                {{ data.item.domain_name_dealer_info.name }}
              </b-link>
              <small class="text-muted">
                <div class="email-text">{{ resolveTDomainAgentId(data.item.domain_name_agent_id) }}</div>
              </small>
            </div>
          </div>
        </template>

        <!-- 欄位: 擁有網域數量 -->
        <template #cell(total)="data">
          <div class="table-col">
            <span>{{ data.item.total ? parseInt(data.item.total).toLocaleString() : 0 }}</span>
          </div>
        </template>

        <!-- 欄位: 創建時間 -->
        <template #cell(created_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.created_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.created_at).format('HH:mm:ss')}`"
              >
                {{ updateOnline(data.item.created_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>

        <!-- 欄位: 更新時間 -->
        <template #cell(updated_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.updated_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.updated_at).format('HH:mm:ss')}`"
              >
                {{ updateOnline(data.item.updated_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>

        <!-- 欄位: 動作 -->
        <template #cell(actions)="data">
          <div class="d-flex mb-50">
            <div
              class="actions-link-btn mr-25"
              @click="onSubmitCopy(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="複製"
                src="/dashboard/admin/images/table/copy.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <!-- <div
              v-if="!searchIsFree"
              class="actions-link-btn mr-25"
            >
              <b-link
                :to="{ name: 'admin-domain-price-setting', params: { id: data.item.id } }"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="編輯"
                  src="/dashboard/admin/images/table/edit.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </b-link>
            </div> -->

            <div
              class="actions-link-btn mr-25"
              @click="onSubmitUpdate(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="編輯"
                src="/dashboard/admin/images/table/edit.svg"
                class="actions-link-btn-image"
                rounded
              />
              <!-- v-if="searchIsFree" -->
            </div>

            <div
              class="actions-link-btn"
              @click="onSubmitDelete(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="刪除"
                src="/dashboard/admin/images/table/delete.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <div class="actions-link-btn mr-25">
              <b-link :to="{ name: 'admin-domain-price-setting', params: { id: data.item.id } }">
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="編輯"
                  src="/dashboard/admin/images/table/menu.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </b-link>
            </div>
          </div>
        </template>
      </b-table>

      <!-- 顯示頁數/分頁 -->
      <div class="m-2">
        <b-row>
          <!-- 顯示頁數 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
          </b-col>

          <!-- 分頁 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalNum"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
// API
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'

// UI
import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BLink, BInputGroupAppend, // BBadge,
  BDropdownForm, BFormInput, BInputGroupPrepend, BImg, BCardHeader, BTabs, BTab,
  BInputGroup, BFormGroup, BFormCheckbox, BCardBody, BSkeletonTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TableTimeRange from '@/layouts/components/Table/TimeRange.vue'

import router from '@/router'
import store from '@/store'

// Component
import { useSwalToast } from '@/libs/mixins/index'
import { useDomainPrice, useDomainSetting } from '../../useDomain'
import useStoreModule from '../../useStoreModule'
import PriceListAddModal from './PriceListAddModal.vue'
import PriceListEditModal from './PriceListEditModal.vue'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BCard,
    BTabs,
    BTab,
    BRow,
    BCol,
    BImg,
    BLink,
    // BBadge,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownForm,
    BCardHeader,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BCardBody,
    BSkeletonTable,
    BInputGroupAppend,

    vSelect,
    TableTimeRange,
    PriceListAddModal,
    PriceListEditModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      title: router.currentRoute.meta.pageTitle,
      selected: null,
    }
  },
  computed: {
    tableColumnsFilter() {
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      return this.tableColumns
        .filter(f => f.searchable)
    },
    adminDomainState() {
      return this.$store.state['admin-domain']
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (檢查)最低價格
    checkArrayAndPrice(price) {
      if (Array.isArray(price)) {
        if (price.length > 0) {
          const firstElement = price[0]
          if (Object.prototype.hasOwnProperty.call(firstElement, 'price')) {
            return `$ ${parseInt(firstElement.price, 10).toLocaleString()}`
          }
        }
      }
      return '---'
    },

    // (轉換)第三方支付名稱
    resolveTDomainAgentId(id) {
      const item = this.adminDomainState.domainAgentOptions.find(f => f.id === id)
      return item ? item.agent : id
    },

    // (觸發)編輯
    onSubmitCopy(item) {
      this.$refs.domainAddModal.getData(item)
    },

    // (觸發)新增/編輯
    onSubmitUpdate(item) {
      if (!item) {
        this.$refs.domainAddModal.getData()
        return
      }
      this.selected = item
      setTimeout(() => { this.$refs.domainEditModal.getData() }, 200)
    },

    // (刪除)網域商品
    onSubmitDelete(item) {
      this.useSwalAlertWarning('刪除商品', `你確定要永久刪除${this.searchIsFree ? '免費' : '付費'}網域商品 ${item.suffix} 嗎? (我是覺得不能刪啦)`)
        .then(result => {
          if (result.value) {
            this.setDomainDelete({
              id: item.id,
            }).then(() => {
              this.refetchData()
              this.useSwalAlertCenter(true, '刪除成功!', '商品已移除')
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },
  },
  setup() {
    // 註冊模組
    const DOMAIN_ADMIN_STORE_MODULE_NAME = 'admin-domain'

    if (!store.hasModule(DOMAIN_ADMIN_STORE_MODULE_NAME)) store.registerModule(DOMAIN_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(DOMAIN_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(DOMAIN_ADMIN_STORE_MODULE_NAME)
    })

    const {
      ui,
      refonlineTime,
      onlineTime,
      updateOnline,
      displayOptions,
      isFireOptions,
      // isFreeOptions,
    } = useDomainSetting()

    const {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      searchAgentId,
      searchIsFree,
      searchIsFire,
      searchDisplay,
      searchDealerId,
      timeRange,
      blankDomainPriceData,
      domainData,
      refetchData,
      refetchTable,
      dealerOptions,

      getDomainListData,
      setDomainDelete,
      useAlertToast,
      useHttpCodeAlert,
    } = useDomainPrice()

    return {
      ui,
      refonlineTime,
      onlineTime,
      updateOnline,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      searchAgentId,
      searchIsFree,
      searchIsFire,
      searchDisplay,
      searchDealerId,
      timeRange,
      blankDomainPriceData,
      domainData,
      refetchData,
      refetchTable,

      getDomainListData,
      setDomainDelete,
      // getDomainAgentData,
      useAlertToast,
      useHttpCodeAlert,
      displayOptions,
      isFireOptions,
      dealerOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.table-col {
  min-width: 40px;
}

.table-title {
  min-width: 100px;
}
.server-name:hover {
  color: red !important;
  text-decoration: underline
}

.border-search {
  display: flex;
  flex-wrap: wrap;
}

.selection-group {
  .selection-btn-icon-show {
    opacity: 0;
    margin-left: 3px;
  }
}

.selection-group:hover {
  .selection-btn-icon-show {
    opacity: 1;
  }
}

.fire-img {
  margin-top: -3px;
}

.nofire {
  opacity: 0.2;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.kyc-state {
  display: flex;
  align-items: center;
  .kyc-state-image {
    max-width: 24px;
    margin-right: 5px;
  }

  .kyc-state-type {
    max-width: 16px;
  }
}
.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}

.dot-yellow-selected {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
}

.dot-red-selected {
  background:radial-gradient(circle closest-side at center,#f6adad,#f32929);
}
</style>
