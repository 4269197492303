var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"add-modal",attrs:{"id":"add-modal","no-close-on-backdrop":"","ok-title":"確認","centered":"","header-bg-variant":"primary","ok-only":""},on:{"hidden":_vm.handleHide,"close":_vm.handleHide},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h4',{staticClass:"m-0 model-header"},[_vm._v(" "+_vm._s(_vm.domainData.id ? '複製' : '新增')+"網域商品 "+_vm._s(_vm.rankTotal)+" ")])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 text-right"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isBusy},on:{"click":_vm.handleOk}},[(!_vm.isBusy)?_c('span',[_vm._v("確認")]):_c('b-spinner',{attrs:{"small":""}})],1)],1)]},proxy:true}])},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{ref:"refDataTypeTable",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"類型","label-for":"is_free"}},[_c('v-select',{staticClass:"edit-col-select",attrs:{"options":_vm.isFreeOptions,"reduce":function (option) { return option.value; },"clearable":false},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}}],null,true),model:{value:(_vm.domainData.is_free),callback:function ($$v) {_vm.$set(_vm.domainData, "is_free", $$v)},expression:"domainData.is_free"}})],1),_c('validation-provider',{attrs:{"name":"網域後綴","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"網域後綴","label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","state":_vm.getValidationState(validationContext),"trim":"","autofocus":"","placeholder":"請輸入網域商品名稱"},model:{value:(_vm.domainData.suffix),callback:function ($$v) {_vm.$set(_vm.domainData, "suffix", $$v)},expression:"domainData.suffix"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"上架狀態","label-for":"display"}},[_c('v-select',{staticClass:"edit-col-select",attrs:{"options":_vm.displayOptions,"reduce":function (option) { return option.value; },"clearable":false},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}}],null,true),model:{value:(_vm.domainData.display),callback:function ($$v) {_vm.$set(_vm.domainData, "display", $$v)},expression:"domainData.display"}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"排序","label-for":"rank"}},[_c('b-form-input',{attrs:{"id":"name","type":"number","placeholder":"請輸入商品排序"},model:{value:(_vm.domainData.rank),callback:function ($$v) {_vm.$set(_vm.domainData, "rank", $$v)},expression:"domainData.rank"}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"身分驗證","label-for":"require_kyc"}},[_c('v-select',{staticClass:"edit-col-select",attrs:{"options":_vm.kycRequiredOptions,"reduce":function (option) { return option.value; },"clearable":false},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}}],null,true),model:{value:(_vm.domainData.require_kyc),callback:function ($$v) {_vm.$set(_vm.domainData, "require_kyc", $$v)},expression:"domainData.require_kyc"}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"熱銷狀態","label-for":"is_fire"}},[_c('v-select',{staticClass:"edit-col-select",attrs:{"options":_vm.isFireOptions,"reduce":function (option) { return option.value; },"clearable":false},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}}],null,true),model:{value:(_vm.domainData.is_fire),callback:function ($$v) {_vm.$set(_vm.domainData, "is_fire", $$v)},expression:"domainData.is_fire"}})],1)],1)],1),_c('b-form-group',{attrs:{"label":"網域商","label-for":"searchAgentId"}},[_c('v-select',{staticClass:"w-100",attrs:{"options":_vm.adminDomainState.domainAgentOptions,"label":"agent","clearable":false,"reduce":function (option) { return option.id; }},on:{"input":function () {
            _vm.getDomainDealerData()
            _vm.domainData.domain_name_dealer_id = null
          }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
          var search = ref.search;
          var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}}],null,true),model:{value:(_vm.searchAgentId),callback:function ($$v) {_vm.searchAgentId=$$v},expression:"searchAgentId"}})],1),_c('b-form-group',{attrs:{"label":"經銷商","label-for":"domain_name_dealer_id"}},[(_vm.searchAgentId)?_c('v-select',{staticClass:"w-100",attrs:{"options":_vm.dealerOptions,"label":"name","clearable":false,"reduce":function (option) { return option.id; },"disabled":_vm.isLoadingBusy,"placeholder":"請選擇經銷商"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
          var search = ref.search;
          var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}}],null,true),model:{value:(_vm.domainData.domain_name_dealer_id),callback:function ($$v) {_vm.$set(_vm.domainData, "domain_name_dealer_id", $$v)},expression:"domainData.domain_name_dealer_id"}}):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }